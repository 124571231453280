/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  FormController,
  FormLabel,
  DrawerBody,
  DrawerFooter,
  SkeletonBlock,
  Typography
} from '@one-thd/sui-atomic-components';
import { InfoFilled } from '@one-thd/sui-icons';
import { useAddToQuote } from '../hooks/useQuotes';
import { DeliveryZipCode } from './DeliveryZipCode';
import { Quotes } from '../shared/Quotes';
import { ProductInfo } from '../shared/ProductInfo';
import { DELIMITER, DELIVERY_FULFILLMENT } from '../shared/Constants';
import { useCustomerInformation } from '../hooks/useCustomerInformation';

const AddToQuoteFormDrawer = ({
  isDeliveryZipEnabled,
  item,
  location,
  onClose,
  product,
  quoteList,
  quotesPageSize,
  selectedQuote,
  setAddToQuoteFormDrawer,
  setCreateNewQuoteDrawer,
  setDeliveryZip,
  setError,
  setErrorDrawer,
  setQuoteName,
  setQuotesPageSize,
  setSelectedQuote,
  setSuccess,
  showLoadMore
}) => {
  const customerInfo = useCustomerInformation();
  const { svocId } = customerInfo;
  const { deliveryZip, localStoreId } = location || {};
  const { itemId, quantity, fulfillmentMethod, fulfillmentLocation } = item || {};
  const price = product?.pricing?.value;

  const itemIsDeliverable = isDeliveryZipEnabled && DELIVERY_FULFILLMENT.includes(fulfillmentMethod);
  const [isDisabled, setDisabled] = useState(itemIsDeliverable && !deliveryZip);

  const [addToQuote, quoteResponse] = useAddToQuote({
    itemId,
    quantity,
    fulfillmentLocation,
    fulfillmentMethod,
    svocId,
    userId: selectedQuote?.userId,
    quoteId: selectedQuote?.quoteId,
    localStoreId,
    deliveryZip,
  });
  const { loading, data, error } = quoteResponse;

  const addItemToQuote = () => {
    addToQuote();
  };

  const triggerAnalytics = () => {
    window.LIFE_CYCLE_EVENT_BUS.trigger('add-to-quote.quoteAdd', {
      quoteType: 'online',
      quoteId: data?.addItemToQuote?.quoteId || data?.addToQuote?.cartId,
      item: {
        quoteLocation: 'pip',
        quantity,
        price: {
          basePrice: price
        },
        productInfo: {
          sku: itemId
        }
      }
    });
  };

  const selectQuote = (evt) => {
    const { value } = evt?.target;
    const [quoteId, userId, quoteName] = value.split(DELIMITER);
    setSelectedQuote({
      userId,
      quoteId,
      quoteName
    });
  };

  const createNewQuoteClick = () => {
    setAddToQuoteFormDrawer(false);
    setCreateNewQuoteDrawer(true);
    window.LIFE_CYCLE_EVENT_BUS.trigger('add-to-quote.createAQuoteOverlay');
  };

  useEffect(() => {
    if (data && !loading && !error) {
      setQuoteName(selectedQuote?.quoteName);
      setAddToQuoteFormDrawer(false);
      setSuccess(true);
      triggerAnalytics();
    } else if (error) {
      setError(error);
      setErrorDrawer(true);
      setAddToQuoteFormDrawer(false);
    }
  }, [data, loading, error]);

  if (loading) {
    return (
      <DrawerBody>
        <SkeletonBlock />
      </DrawerBody>
    );
  }

  return (
    <>
      <DrawerBody>
        <ProductInfo product={product} />

        {isDeliveryZipEnabled && DELIVERY_FULFILLMENT.includes(fulfillmentMethod) && (
          <DeliveryZipCode
            deliveryZip={deliveryZip}
            setDeliveryZip={setDeliveryZip}
            setDisabled={setDisabled}
          />
        )}

        <Button
          className="sui-mb-4 sui-mt-6 sui-text-base sui-flex"
          variant="text"
          onClick={createNewQuoteClick}
          data-testid="create-quote"
          disabled={isDisabled}
        >
          <u>Create New Quote</u>
        </Button>
        <div className="sui-pb-4 sui-text-base">-or-</div>
        <FormController>
          <FormLabel id="quote-names" className="sui-cursor-default sui-text-base">
            Add to an Existing Quote:
          </FormLabel>
          <div className="sui-pt-4 sui-pr-2 sui-flex">
            <div className="sui-pr-2 sui-pt-1">
              <InfoFilled color="info" size="small" />
            </div>
            <Typography variant="body-sm" color="info">
              Prices will update when adding items to or changing the ZIP Code of an existing quote
            </Typography>
          </div>
          <Quotes
            quoteList={quoteList}
            selectedQuote={selectedQuote}
            selectQuote={selectQuote}
            showLoadMore={showLoadMore}
            quotesPageSize={quotesPageSize}
            setQuotesPageSize={setQuotesPageSize}
          />
        </FormController>
      </DrawerBody>
      <DrawerFooter>
        <ButtonGroup orientation="vertical">
          <Button
            fullWidth
            variant="primary"
            onClick={addItemToQuote}
            disabled={!selectedQuote?.quoteId?.length || isDisabled}
            data-testid="add-to-existing-quote"
          >
            Add to Quote
          </Button>
          <Button fullWidth variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </ButtonGroup>
      </DrawerFooter>
    </>
  );
};

AddToQuoteFormDrawer.propTypes = {};

AddToQuoteFormDrawer.displayName = 'AddToQuoteFormDrawer';

export { AddToQuoteFormDrawer };
