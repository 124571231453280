/* eslint-disable tailwindcss/no-arbitrary-value */
import React, { useEffect, useRef, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  params, string, arrayOf, customType, shape,
  number, useLazyDataModel, bool, extend
} from '@thd-nucleus/data-sources';
import {
  RadioGroup, Radio, Button,
  SelectionControlLabel,
  DrawerFooter, DrawerBody,
  SkeletonLine
} from '@one-thd/sui-atomic-components';
import { AddToProductDetails } from '../../common/AddToProductDetails/AddToProductDetails';
import { SCREENS } from '../../AddToConstants';
import { AddToContext } from '../../AddToContext';

export const SelectProject = ({
  products,
  projects,
  projectsExist,
  selectedProject,
  setSelectedProject,
  groupsExist,
  pageSize,
  setPageSize,
  projectsCount,
  loading,
  setInitialLoad
}) => {
  const scrollableDivRef = useRef(null);
  const sentinelRef = useRef(null);
  const [isBottom, setIsBottom] = useState(false);
  const { setScreen, handleClose } = useContext(AddToContext);
  const [projectInformation, setProjectInformation] = useState({
    projectId: '', projectName: ''
  });
  const { PROJECT_CREATE_PROJECT, PROJECT_CREATE_GROUP, PROJECT_SELECT_GROUP } = SCREENS;
  const onSelectProject = (project) => {
    setProjectInformation({
      projectId: project?.id,
      projectName: project?.projectName
    });
  };
  const addItemToProject = () => {
    setSelectedProject({
      projectId: projectInformation.projectId,
      projectName: projectInformation.projectName
    });
    if (groupsExist) {
      setScreen(PROJECT_SELECT_GROUP);
    } else {
      setScreen(PROJECT_CREATE_GROUP);
    }
  };

  const onBottomReached = () => {
    if (isBottom && projectsCount >= pageSize && projectsCount > 0) {
      let newPageSize = pageSize + 30;
      if (projectsCount >= newPageSize) {
        setInitialLoad(false);
        setPageSize(newPageSize);
        setIsBottom(false);
      } else {
        setInitialLoad(false);
        setPageSize(projectsCount);
        setIsBottom(false);
      }
    };
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (!loading) {
              setIsBottom(true);
            }
            // observer.disconnect();
            onBottomReached?.();
          } else {
            setIsBottom(false);
          }
        });
      },
      {
        threshold: 1,
        root: scrollableDivRef.current,
        rootMargin: '0px'
      }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [onBottomReached]);


  return (
    <>
      <DrawerBody id="select-project" ref={scrollableDivRef} overflow="scroll">
        {products?.length <= 1 && (
          <AddToProductDetails products={products} />
        )}
        <div className="sui-pl-5">
          {
            projectsExist && (
              <>
                <div>
                  <Button
                    className="sui-mb-4 sui-mt-12 sui-mx-6 sui-text-base sui-flex"
                    variant="text"
                    onClick={() => { setScreen(PROJECT_CREATE_PROJECT); }}
                  >
                    <u>Create New Project</u>
                  </Button>
                </div>
                <div className="sui-px-8 sui-py-3">-or-</div>
                <RadioGroup
                  aria-labelledby="add-to-buttons-group-label"
                  name="radio-buttons-group"
                  value={projectInformation?.id}
                >
                  {
                    projects?.map((project, i) => (
                      <SelectionControlLabel
                        value={project.projectId}
                        label={project.projectName}
                        key={project.projectId}
                        onChange={(evt) => { onSelectProject(project); }}
                      >
                        <Radio />
                      </SelectionControlLabel>
                    ))
                  }
                </RadioGroup>
                {loading && (
                  <SkeletonLine fullWidth variant="single" numberOfLines={2} />
                )}
                {!loading && (
                  <div ref={sentinelRef} style={{ height: '1px' }} />
                )}
              </>
            )
          }
        </div>
      </DrawerBody>
      <DrawerFooter position="center" data-testid="add-to-project-add-to-project-footer">
        <div className="sui-flex sui-flex-col sui-gap-1">
          <Button
            data-testid="add-to-project-add-button"
            variant="primary"
            fullWidth
            disabled={!projectsExist || !projectInformation?.projectId}
            onClick={() => { addItemToProject(); }}
            type="submit"
          > Next
          </Button>
          <Button
            data-testid="add-to-project-cancel-button"
            variant="secondary"
            fullWidth
            onClick={handleClose}
            type="submit"
          >
            Cancel
          </Button>
        </div>
      </DrawerFooter>
    </>
  );
};

SelectProject.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    itemId: PropTypes.string,
    quantity: PropTypes.number,
    price: PropTypes.number
  })),
  projects: PropTypes.arrayOf(PropTypes.shape({
    projectId: PropTypes.string,
    projectName: PropTypes.string
  })),
  projectsExist: PropTypes.bool,
  selectedProject: PropTypes.shape({
    projectId: PropTypes.string,
    projectName: PropTypes.string
  }),
  setSelectedProject: PropTypes.func.isRequired,
  groupsExist: PropTypes.bool,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  projectsCount: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  setInitialLoad: PropTypes.func.isRequired
};

SelectProject.defaultProps = {
  products: [],
  projects: [],
  projectsExist: false,
  selectedProject: {},
  groupsExist: false,
  loading: false
};