/* eslint-disable tailwindcss/no-custom-classname */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  arrayOf, customType, extend, params, QueryProvider, shape, string, number, useLazyDataModel
} from '@thd-nucleus/data-sources';
import { useStore } from '@thd-nucleus/experience-context';
import { AddToProductPod } from '../addto-product-pod/AddToProductPod';
import { PAGE_SIZE } from '../../AddToConstants';
import { generateLoadedProducts, getNvalue } from '../../AddToHelpers';
import { AddToPodPlaceHolder } from '../addto-product-pod/AddToPodPlaceHolder';

const HoistedSearchResultWrapper = ({ children }) => children;
HoistedSearchResultWrapper.dataModel = extend(AddToProductPod);

export const SearchResults = ({ keyword, groupsExist }) => {
  const { storeId } = useStore();
  const [startIndex, setStartIndex] = useState(0);
  const [productsLoaded, setProductsLoaded] = useState({
    keyword: '', totalProducts: 0, products: [], nValue: null
  });
  const [nValueMap, setNValueMap] = useState({});
  const productResultRef = useRef(null);
  let paginationObserver = null;
  const NO_OF_PLACEHOLDERS = 4;

  const [searchProducts, response] = useLazyDataModel('searchModel', {
    variables: {
      storeId,
      pageSize: PAGE_SIZE,
      startIndex
    },
    skip: !keyword?.length
  });

  const disconnect = () => {
    if (paginationObserver && paginationObserver?.disconnect) {
      paginationObserver.disconnect();
    }
  };

  // Re trigger the searchNav call with nValue if a keyword is associated with an nValue (eg: 'sofa')
  // In PLP pages users will be redirected to browse page for this scenario.
  useEffect(() => {
    if (keyword?.length) {
      disconnect();
      const variables = {};
      let nValue = null;
      if (nValueMap[keyword]) {
        nValue = nValueMap[keyword];
        variables.navParam = nValue;
      } else {
        variables.keyword = keyword;
      }
      setProductsLoaded({ keyword, totalProducts: 0, products: [], nValue });
      searchProducts({ variables });
    }
  }, [keyword, nValueMap]);

  const loadMore = () => {
    const newStartIndex = startIndex + PAGE_SIZE;
    const variables = {
      startIndex: newStartIndex
    };
    if (productsLoaded.nValue) {
      variables.navParam = productsLoaded.nValue;
    } else {
      variables.keyword = keyword;
    }
    searchProducts({ variables });
    setStartIndex(newStartIndex);
  };

  const {
    data, loading,
  } = response || {};
  const { products, searchReport, metadata } = data?.searchModel || [];

  useEffect(() => {
    if (products?.length) {
      const updatedProducts = generateLoadedProducts(productsLoaded.products, products);
      setProductsLoaded({
        ...productsLoaded,
        totalProducts: searchReport?.totalProducts || 0,
        products: updatedProducts
      });
    } else {
      const { searchRedirect } = metadata || {};
      if (searchRedirect) {
        const nValue = getNvalue(searchRedirect);
        setProductsLoaded({
          ...productsLoaded, nValue
        });
        setNValueMap({ ...nValueMap, [keyword]: nValue });
      }
    }
  }, [products, loading]);

  useEffect(() => {
    return () => {
      disconnect();
      setProductsLoaded({ keyword: '', totalProducts: 0, products: [], nValue: null });
    };
  }, []);

  const observe = (elem, cb) => {
    const observerOptions = {
      rootMargin: '0px 0px 300px 0px'
    };
    paginationObserver = new IntersectionObserver(async (_ref2, obs) => {
      let [entry] = _ref2;
      if (!entry.isIntersecting) return;
      disconnect();
      cb();
    }, observerOptions);
    paginationObserver.observe(elem);
  };

  useEffect(() => {
    if (productsLoaded.products?.length > 0 && productsLoaded.products?.length < productsLoaded.totalProducts) {
      const displayiedPods = productResultRef.current?.querySelectorAll('.search-result-pod');
      const totalItems = displayiedPods?.length;
      if (totalItems > 0) {
        const lastChild = displayiedPods[totalItems - 1];
        disconnect();
        observe(lastChild, loadMore);
      }
    }
  }, [productsLoaded.products?.length]);

  const defaultVariable = {
    storeId
  };

  if (!keyword) return null;

  const numberOfPlaceHolders = productsLoaded.products?.length === 0 ? NO_OF_PLACEHOLDERS
    : Math.min(
      productsLoaded.totalProducts - productsLoaded.products.length, NO_OF_PLACEHOLDERS
    );

  return (
    <QueryProvider defaultVariables={defaultVariable} cacheKey="pr-projects-search-results" dataSource="searchNav">
      <HoistedSearchResultWrapper>
        <div ref={productResultRef} data-testid="search-results" className="sui-grid sui-grid-cols-2 sui-gap-6">
          {
            productsLoaded.products.map((product, index) => (
              <div className="search-result-pod sui-flex" key={index}>
                <AddToProductPod
                  itemId={product.itemId}
                  groupsExist={groupsExist}
                />
              </div>
            ))
          }
          {
            loading && <AddToPodPlaceHolder count={numberOfPlaceHolders} />
          }
        </div>
      </HoistedSearchResultWrapper>
    </QueryProvider>
  );
};

SearchResults.propTypes = {
  keyword: PropTypes.string,
  groupsExist: PropTypes.bool
};

SearchResults.defaultProps = {
  keyword: '',
  groupsExist: false
};

SearchResults.dataModel = extend({
  searchModel: params({
    keyword: string(),
    navParam: string(),
    storeId: string(),
    storefilter: customType('StoreFilter').enum(['ALL', 'IN_STORE', 'ONLINE'], 'ALL'),
    loyaltyMembershipInput: customType('LoyaltyMembershipInput').shape({
      svocID: string(),
      programTiers: arrayOf(
        shape({
          tier: string(),
          program: string()
        })
      )
    }),
    additionalSearchParams: customType('AdditionalParams').shape({
      deliveryZip: string(),
    }),
  }).shape({
    metadata: shape({
      searchRedirect: string()
    }),
    searchReport: shape({
      pageSize: number(),
      startIndex: number(),
      totalProducts: number()
    }),
    products: params({
      startIndex: number(),
      pageSize: number()
    })
      .arrayOf(AddToProductPod.dataModel.product)
  })
});
