/* eslint-disable max-len */
import { FULFILLMENT_METHODS } from '../constants';
import {
  getDeliveryFfmTypeToUse,
  getMaxDeliverableQty,
  getPreferredFfmFromAPI,
  isMockOptimize
} from '../helper/utils';
import {
  handleDefaultUnavailable,
  handleExpressUnavailable,
  handleFallbackMode,
  handleFulfillable,
  handleNoFfmOptions,
  handleUnavailableFfms
} from './deliveryTemplateGeneratorHelper';

export const getDeliveryTemplate = ({
  fulfillment,
  type,
  zipCode,
  storeId,
  quantity,
  itemId,
  setShowCheckAvailability,
  pricing,
  currentDeliveryFfm,
  shippingData,
  preferSecondaryFFM,
  isCartTiles = false,
  fallbackMode = false,
  isSpecialOrder,
  configuratorHideQuantity,
  isConfigurableBlinds,
  disableTimer,
  disableSTH,
  disableBODFS,
  hideShippingThreshold
}) => {
  const deliveryService = fulfillment?.fulfillmentOptions?.find(
    (option) => option.type === 'delivery'
  );
  const shippingFulfillment = !disableSTH
    ? deliveryService?.services?.find((service) => service.type === 'sth')
    : null;
  const shippingState = shippingFulfillment?.locations[0]?.state;
  const shippingLocation = shippingFulfillment?.locations?.find(
    (location) => location?.type === 'online'
  );
  const expressFulfillment = !disableBODFS
    ? deliveryService?.services?.find((service) => service.type === 'express delivery')
    : null;
  const bodfsLocation = expressFulfillment?.locations.find(
    (location) => location?.type === 'store'
  );
  const maxInventoryAvail = getMaxDeliverableQty({ shippingLocation, bodfsLocation });
  const isBackordered = fulfillment?.backordered;
  const isBodfsGrillsAssemblyEligible = fulfillment?.bodfsAssemblyEligible || false;

  // set preferedFFM as secondary on the basis of the flag: preferSecondaryFFM
  const preferredFfmFromAPI = getPreferredFfmFromAPI(
    fulfillment,
    preferSecondaryFFM,
    isCartTiles,
    currentDeliveryFfm
  );

  // can tie cheapest and fast here to preferredFfmFromAPI field
  const fulfillmentToUse = getDeliveryFfmTypeToUse({
    quantity,
    shippingLocation,
    bodfsLocation,
    currentDeliveryFfm,
    // subscriptionEligible, // uncomment this line to force STH when subscription eligible
    preferredFfmFromAPI,
    pricing,
    isBackordered
  });

  const fallbackModeEnabled = fallbackMode && !fulfillment?.sthExcludedShipState;
  const isShippingFulfillment = shippingFulfillment && fulfillmentToUse === FULFILLMENT_METHODS.STH;
  const isExcludedState = fulfillment?.sthExcludedShipState && !deliveryService?.fulfillable;
  const isMockOrExpress = isMockOptimize() || expressFulfillment;

  return (
    // Fallback mode
    handleFallbackMode({
      configuratorHideQuantity,
      disableTimer,
      expressFulfillment,
      fallbackModeEnabled,
      hideShippingThreshold,
      isBodfsGrillsAssemblyEligible,
      isCartTiles,
      isConfigurableBlinds,
      isSpecialOrder,
      itemId,
      quantity,
      shippingData,
      shippingFulfillment,
      storeId,
      zipCode
    })
    // No fulfillment options
    || handleNoFfmOptions({
      fulfillment,
      fulfillmentOptions: fulfillment?.fulfillmentOptions,
      shippingState,
      setShowCheckAvailability,
      type
    })
    // Unavailable fulfillment methods
    || handleUnavailableFfms({
      fulfillment,
      itemId,
      quantity,
      setShowCheckAvailability,
      isExcludedState,
      isMockOrExpress,
      isShippingFulfillment,
      shippingFulfillment,
      shippingState,
      storeId,
      zipCode
    })
    // Fulfillable
    || handleFulfillable({
      bodfsLocation,
      configuratorHideQuantity,
      disableSTH,
      disableTimer,
      expressFulfillment,
      fulfillable: deliveryService?.fulfillable,
      fulfillment,
      hideShippingThreshold,
      isBodfsGrillsAssemblyEligible,
      isCartTiles,
      isConfigurableBlinds,
      isShippingFulfillment,
      isSpecialOrder,
      itemId,
      maxInventoryAvail,
      quantity,
      shippingData,
      shippingFulfillment,
      shippingLocation,
      storeId,
      zipCode
    })
    // Express unavailable
    || handleExpressUnavailable(expressFulfillment)
    // Default scenario
    || handleDefaultUnavailable({
      fulfillment,
      type
    })
  );
};
