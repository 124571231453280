/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  DrawerBody,
  DrawerFooter,
  FormController,
  FormLabel,
  TextField,
  SkeletonBlock
} from '@one-thd/sui-atomic-components';
import { ProductInfo } from '../shared/ProductInfo';
import { ERROR, DELIVERY_FULFILLMENT } from '../shared/Constants';
import { useCreateQuote } from '../hooks/useQuotes';
import { useCustomerInformation } from '../hooks/useCustomerInformation';

const CreateNewQuoteDrawer = ({
  isDeliveryZipEnabled,
  item,
  location,
  onClose,
  product,
  setCreateNewQuoteDrawer,
  setError,
  setErrorDrawer,
  setQuoteName,
  setSuccess,
}) => {
  const [quoteNameError, setQuoteNameError] = useState(null);
  const [newQuoteName, setNewQuoteName] = useState('');
  const { deliveryZip, localStoreId } = location || {};
  const { itemId, quantity, fulfillmentLocation, fulfillmentMethod } = item || {};
  const price = product?.pricing?.value;
  const customerInfo = useCustomerInformation();

  const [createQuote, quoteResponse] = useCreateQuote({
    itemId,
    quantity,
    fulfillmentLocation,
    fulfillmentMethod,
    ...customerInfo,
    quoteName: newQuoteName,
    localStoreId,
    deliveryZip,
    isNew: true
  });
  const { loading, data, error } = quoteResponse;

  const createANewQuote = () => {
    createQuote();
  };

  const getValidationError = (value) => {
    const validCharacters = /^[a-zA-Z0-9\s]*$/;
    const isBlank = value.trim().length === 0;
    let isValid = validCharacters.test(value);
    if (!isValid || isBlank) {
      if (isBlank) {
        setQuoteNameError(ERROR.EMPTY_QUOTE_NAME);
      } else {
        setQuoteNameError(ERROR.INVALID_QUOTE_NAME);
      }
    } else {
      setQuoteNameError(null);
    }
  };

  const triggerAnalytics = () => {
    window.LIFE_CYCLE_EVENT_BUS.trigger('add-to-quote.newQuoteAdd', {
      quoteType: 'online',
      quoteId: data?.createQuote?.quoteId || data?.createQuote?.cartId,
      item: {
        quoteLocation: 'pip',
        quantity,
        price: {
          basePrice: price
        },
        productInfo: {
          sku: itemId
        }
      }
    });
  };

  useEffect(() => {
    if (data && !loading && !error) {
      setQuoteName(newQuoteName);
      setNewQuoteName('');
      setCreateNewQuoteDrawer(false);
      setSuccess(true);
      triggerAnalytics();
    } else if (error) {
      setError(error);
      setCreateNewQuoteDrawer(false);
      setErrorDrawer(true);
    }
  }, [data, loading, error]);

  if (loading) {
    return (
      <DrawerBody>
        <SkeletonBlock />
      </DrawerBody>
    );
  }

  return (
    <>
      <DrawerBody>
        <ProductInfo product={product} />

        <FormController className="sui-pt-6" disabled>
          {isDeliveryZipEnabled && DELIVERY_FULFILLMENT.includes(fulfillmentMethod) && (
            <FormController className="sui-mt-6">
              <FormLabel className="sui-cursor-default sui-text-base">
                Delivery Zip Code
              </FormLabel>
              <div>
                <p className="sui-font-bold sui-text-base">{deliveryZip}</p>
              </div>
            </FormController>
          )}
        </FormController>

        <FormController className="sui-pt-6">
          <FormLabel id="create-quote" className="sui-cursor-default sui-text-base">
            Quote Name *
          </FormLabel>
          <div>
            <TextField
              fullWidth
              placeholder="Enter a Quote Name"
              value={newQuoteName}
              onChange={(evt) => {
                setNewQuoteName(evt.target.value);
                getValidationError(evt.target.value);
              }}
              status={quoteNameError ? 'error' : null}
              statusMessage={quoteNameError}
            />
          </div>
        </FormController>
      </DrawerBody>
      <DrawerFooter>
        <ButtonGroup orientation="vertical">
          <Button
            fullWidth
            variant="primary"
            onClick={createANewQuote}
            disabled={!newQuoteName?.length || !!quoteNameError}
            data-testid="create-new-quote"
          >
            Add to Quote
          </Button>
          <Button fullWidth variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </ButtonGroup>
      </DrawerFooter>
    </>
  );
};

CreateNewQuoteDrawer.propTypes = {};

CreateNewQuoteDrawer.displayName = 'CreateNewQuoteDrawer';

export { CreateNewQuoteDrawer };
