export function useCustomerInformation() {
  if (typeof window !== 'undefined') {
    if (window?.THDCustomer?.default) {
      const { svocID, userID, role } = window.THDCustomer.default;

      return { svocId: svocID, userId: userID, role: role?.toUpperCase() };
    }
  }

  return {};
}
