import React, { useEffect } from 'react';
import {
  ENGLISH_LANG_CODE,
  GOOGLE_TRANSLATE_ELEMENT_ID,
  TRANSLATE_INIT_CALLBACK_NAME,
} from '../commons/constants';

const NoTranslateElementsSelectorMap = {
  header_store_address: 'button[aria-label^="Open drawer to view my store"]',
  drawer_store_pod_name: 'a[data-component^="store-pod:StorePodName"]',
  drawer_store_pod_address: 'div[data-component^="store-pod:StorePodAddress"]',
  order_by_plp: 'div[id^="sort-menu"]',
  act_drawer: 'div[class^="thd-drawer thd-drawer__default-screen"]',
  footer_email_form: 'button[id^="footer-email-button"]',
  pip_banner: 'div[class^="sponsored_pip_banner_container"]',
  basic_sponsored_banner_id: 'div[id^="basic-sponsored-banner"]',
  sponsored_standard_banner: 'div[id^="sponsored-standard-banner-nucleus"]',
  hp_sponsored__hero_container: 'div[id^="hp_sponsored__hero_container"]',
  sponsored_ingrid_banner_container: 'div[class^="sponsored_ingrid_banner_container"]',
  sponsored_leaderboard_banner: 'div[id^="sponsored-leaderboard-banner"]',
  non_endemic_banner_container: 'div[id^="non-endemic-banner-container"]',
  sponsored_pip_banner_container: 'div[class^="sponsored_pip_banner_container"]',
  sponsored_skyscraper_banner: 'div[id^="sponsored-skyscraper-banner"]',
  visual_nav_container: 'div[class^="visual-nav-container"]'
};

const preventTranslations = () => {
  const NoTranslateSelectors = Object.values(NoTranslateElementsSelectorMap);

  NoTranslateSelectors.forEach((selector) => {
    const elements = document.body.querySelectorAll(selector);

    if (elements.length > 0) {
      elements.forEach((el) => el.setAttribute('translate', 'no'));
    }
  });
};

const fixTranslationsList = [
  {
    ogText: 'añadir a la cesta',
    fixedText: 'Añadir al Carrito',
  },
  {
    ogText: 'Proveedores y proveedores',
    fixedText: 'Proveedores y Suministradores',
  },
  {
    ogText: 'Hazlo tú mismo',
    fixedText: 'DIY',
  },
];

const applyCustomTranslations = () => {
  fixTranslationsList.forEach(({ ogText, fixedText }) => {
    const elements = document.querySelectorAll('font:not([customized])');

    if (elements.length > 0) {
      elements.forEach((element) => {
        if (new RegExp(ogText, 'i').test(element.innerText)) {
          // eslint-disable-next-line no-param-reassign
          element.innerText = fixedText;
          element.setAttribute('customized', 'true');
        }
      });
    }
  });
};

export const TranslationLogic = () => {
  useEffect(() => {
    // Insert Style rules
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `
      #google_translate_element {
        display: none;
      }
      .goog-te-gadget img{
        display:none !important;
      }
      body > .skiptranslate {
        display: none;
      }
      body {
        top: 0px !important;
      }
    `;
    document.head.appendChild(style);
    // Insert Script
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://translate.google.com/translate_a/element.js?cb=${TRANSLATE_INIT_CALLBACK_NAME}`;
    script.async = true;
    script.id = 'google_translate_element_script';

    document.body.insertBefore(script, document.body.firstChild);

    window[TRANSLATE_INIT_CALLBACK_NAME] = function () {
      // eslint-disable-next-line no-new
      new window.google.translate.TranslateElement(
        { pageLanguage: ENGLISH_LANG_CODE },
        GOOGLE_TRANSLATE_ELEMENT_ID
      );
    };

    const observer = new MutationObserver(() => {
      preventTranslations();
      applyCustomTranslations();
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div id={GOOGLE_TRANSLATE_ELEMENT_ID} />;
};
