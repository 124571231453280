import React from 'react';
import { extend } from '@thd-nucleus/data-sources';
import {
  PodSpacer,
  ProductPod,
  ProductImage,
  ProductIdentifier,
  ProductRating,
} from '@thd-olt-component-react/product-pod';
import {
  bool as boolType,
  string as stringType,
  number as numType
} from 'prop-types';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { Price } from '@thd-olt-component-react/price';
import { SuperSkuMiniSwatches } from '@thd-olt-component-react/super-sku';

export const DiscoveryZonesPod = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    itemId,
    storeId,
    position,
    parent,
    scheme,
    anchorProduct,
    brandTitleMaxLine,
    zoneName,
    zonePosition,
    podResults,
    hideRatings
  } = props;

  return (
    <ProductPod
      itemId={itemId}
      storeId={storeId}
      analyticsData={{
        parent, position, scheme, anchorProduct, zoneName, zonePosition, podResults
      }}
      render={(pod) => (
        <>
          <ProductImage
            itemId={pod.itemId}
            showSecondaryImage={pod.showSecondaryImage}
            onClick={() => { }}
          />
          <PodSpacer padding={['LEFT', 'RIGHT']}>
            <ProductHeader
              brand="above"
              itemId={pod.itemId}
              brandTitleMaxLine={brandTitleMaxLine}
              disableShopThisCollection
              productNameLineHeight="tight"
            />
            {!hideRatings
            && <ProductRating itemId={pod.itemId} />}
          </PodSpacer>
          <PodSpacer padding={['T4']}>
            <Price
              itemId={pod.itemId}
              large={false}
              storeId={storeId}
              displayEachUom={false}
              showPreferredPricingBadge
            />
          </PodSpacer>
        </>
      )}
    />
  );
};

DiscoveryZonesPod.dataModel = extend(
  ProductPod,
  ProductImage,
  ProductIdentifier,
  ProductHeader,
  SuperSkuMiniSwatches,
  Price,
  ProductRating,
);

DiscoveryZonesPod.propTypes = {
  storeId: stringType,
  position: numType,
  parent: stringType,
  scheme: stringType,
  anchorProduct: stringType,
  brandTitleMaxLine: numType,
  zoneName: stringType,
  zonePosition: stringType,
  podResults: numType,
  hideRatings: boolType
};

DiscoveryZonesPod.defaultProps = {
  storeId: '',
  position: '',
  parent: '',
  scheme: 'drecs',
  anchorProduct: '',
  brandTitleMaxLine: 4,
  zoneName: '',
  zonePosition: '',
  podResults: 0,
  hideRatings: false
};
DiscoveryZonesPod.displayName = 'DiscoveryZonesPod';
