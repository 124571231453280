import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  string, shape
} from 'prop-types';
import { useConfigService, ExperienceContext, useStoreId } from '@thd-nucleus/experience-context';
import { useDataModel } from '@thd-nucleus/data-sources';
import { GlobeIcon } from '../assets/icons/GlobeIcon';
import { ControllerPopover } from './ControllerPopover';
import { useLanguagePreference } from '../commons/hooks/useLanguagePreference';
import { TranslationLogic } from './TranslationLogic';
import { ENGLISH_LANG_CODE } from '../commons/constants';
import { dataModel } from './dataModel';
import { getCategories } from '../commons/helper';
import { IncludeStoreList } from '../commons/StoreList';

const TranslatorWidget = () => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('translator_widget.ready');
  }, []);

  const [language, setLanguage] = useLanguagePreference();
  const [popoverAnchorElement, setPopoverAnchorElement] = useState(null);
  const globeIconRef = useRef(null);

  const openPopover = () => {
    setPopoverAnchorElement(globeIconRef.current);
  };

  const closePopover = () => {
    setPopoverAnchorElement(null);
  };

  const handleChange = (event) => {
    setLanguage(event.target.value);

    if (event.target.value === ENGLISH_LANG_CODE) {
      /*
        Explanation: This code is to manipulate the Google Widget directly,
        triggering the "revert Translation" button when we select english
      */
      const translateWidgetIframe = document.getElementById(':1.container');
      const showOriginalButton = translateWidgetIframe.contentWindow.document.getElementById(':1.restore');
      showOriginalButton.click();
    } else {
      const translateElement = document.querySelector('.goog-te-combo');
      translateElement.value = event.target.value;
      translateElement.dispatchEvent(new Event('change', { bubbles: true }));
    }
  };

  return (
    <div translate="no">
      <TranslationLogic />

      <button
        type="button"
        className="sui-flex sui-justify-center sui-items-center sui-gap-1 sui-underline"
        onClick={openPopover}
        ref={globeIconRef}
      >
        <GlobeIcon />
        {language === ENGLISH_LANG_CODE ? 'English' : 'Español'}
      </button>

      <ControllerPopover
        anchor={popoverAnchorElement}
        close={closePopover}
        preferredLanguage={language}
        handleChange={handleChange}
      />
    </div>
  );
};

TranslatorWidget.propTypes = {};
TranslatorWidget.displayName = 'TranslatorWidget';

const ConnectedTranslatorWidget = (props) => {
  const {
    itemId,
    keyword,
    navParam,
    pageType,
    data: propData
  } = props;
  const { isConsumerApp } = useContext(ExperienceContext);
  const storeId = useStoreId();
  const isTranslatorWidgetEnabled = useConfigService('fs:isTranslatorWidgetEnabled') && !isConsumerApp;
  const translatorExceptionListCS = useConfigService('translatorExceptionList');
  const translatorExceptionList = typeof translatorExceptionListCS === 'string'
    ? JSON.parse(translatorExceptionListCS)
    : translatorExceptionListCS || {};
  const exclusionCategoryList = translatorExceptionList?.categories;
  const storesToExcludeFromCS = translatorExceptionList?.stores?.exclusion || [];
  const storesToIncludeFromCS = translatorExceptionList?.stores?.inclusion || [];
  // Create the final storesToInclude array by filtering out excluded stores
  const storesToInclude = [
    ...new Set([
      ...IncludeStoreList,
      ...storesToIncludeFromCS
    ])
  ].filter((store) => !storesToExcludeFromCS.includes(store));

  const productResponse = useDataModel('product', {
    skip: !itemId,
    variables: {
      itemId
    }
  });

  const skipSearch = itemId || propData || (!keyword && !navParam);

  const opts = {
    skip: skipSearch,
    variables: { keyword, navParam }
  };
  const searchResponse = useDataModel('searchModel', opts);

  let data;
  let loading = false;
  if (propData !== null) {
    data = propData;
  } else if (itemId) {
    data = productResponse?.data;
    loading = productResponse?.loading;
  } else {
    data = searchResponse?.data;
    loading = searchResponse?.loading;
  }

  if (loading && !data) {
    return null;
  }

  const categories = getCategories(data, pageType);

  const isCategoryExcluded = exclusionCategoryList?.some((excludeItem) => {
    return categories.some((category) => category.label.trim() === excludeItem.trim());
  });

  const isStoreIncluded = storesToInclude?.includes(storeId);

  // - Do not show Translator Widget if current Category is in the exclusion list
  // - Do not show Translator Widget if the FS is false
  // - Do not show Translator Widget if current Store is in the exclusion store list
  if (!isTranslatorWidgetEnabled || !data || isCategoryExcluded || !isStoreIncluded) {
    return null;
  }

  return <TranslatorWidget />;
};

ConnectedTranslatorWidget.displayName = 'ConnectedTranslatorWidget';
ConnectedTranslatorWidget.dataModel = dataModel;
ConnectedTranslatorWidget.propTypes = {
  itemId: string,
  keyword: string,
  navParam: string,
  pageType: string,
  data: shape({}),
};
ConnectedTranslatorWidget.defaultProps = {
  itemId: null,
  keyword: null,
  navParam: null,
  pageType: '',
  data: null,
};
export { ConnectedTranslatorWidget };
