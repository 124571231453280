import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { DrawerBody, DrawerFooter, Button } from '@one-thd/sui-atomic-components';
import { Specifications } from '@thd-olt-component-react/specifications';
import { AddToContext } from '../../AddToContext';
import { SCREENS } from '../../AddToConstants';

export const ProductSpecs = ({ itemId }) => {
  const { setScreen } = useContext(AddToContext);
  const { PROJECT_SELECT_GROUP } = SCREENS;

  return (
    <>
      <DrawerBody>
        <Specifications itemId={itemId} hideMobileFade oneColumn />
      </DrawerBody>
      <DrawerFooter position="center">
        <Button
          data-testid="productSpecs-AITP-button"
          variant="secondary"
          fullWidth
          onClick={() => setScreen(PROJECT_SELECT_GROUP)}
          type="submit"
        >
          Add to Project
        </Button>
      </DrawerFooter>
    </>
  );
};

ProductSpecs.displayName = 'ProductSpecs';

ProductSpecs.propTypes = {
  itemId: PropTypes.string.isRequired
};

ProductSpecs.dataModel = extend(Specifications);
