import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import {
  Alert, Button, Link,
  DrawerBody, DrawerFooter
} from '@one-thd/sui-atomic-components';
import { SearchProducts } from '../../common/search-products/SearchProducts';
import { PreviouslyPurchased } from '../../common/previously-purchased/PreviouslyPurchased';
import { SearchResults } from '../../common/search-products/SearchResults';
import { AddToContext } from '../../AddToContext';

export const ProjectDefaultScreen = ({
  keyword,
  onSearch,
  handleClose,
  projectName,
  projectId,
  selectedGroup,
  groupsExist,
  onSuccessGroupClick,
  selectedGroupId,
  products,
  selectedProject
}) => {
  const { showSuccessMessage, setShowSuccessMessage } = useContext(AddToContext);
  const selectedProjectId = projectId || selectedProject.projectId;
  const selectedProjectName = projectName || selectedProject.projectName;

  const handleClick = () => {
    handleClose();
    onSuccessGroupClick(selectedGroupId.current);
    window.location.href = `/projects/${selectedProjectId}#group=${selectedGroupId.current}`;
  };

  const isMultipleProducts = products?.length > 1;

  return (
    <>
      <DrawerBody overflow="scroll">
        {showSuccessMessage && (
          <Alert
            status="success"
            onClose={() => setShowSuccessMessage(false)}
            data-testid="add-to-success-alert"
          >
            {isMultipleProducts ? 'Items' : 'Item'} successfully added to <Link href={`/projects/${selectedProjectId}`}>{selectedProjectName}</Link>&nbsp;
            in group <Link component="button" onClick={handleClick}>{selectedGroup}</Link>&nbsp;
          </Alert>
        )}
        {!products?.length > 0 && (
          <>
            <SearchProducts onSubmit={onSearch} />
            {keyword.length === 0 && (
              <PreviouslyPurchased
                groupsExist={groupsExist}
              />
            )}
            <SearchResults
              keyword={keyword}
              groupsExist={groupsExist}
            />
          </>
        )}
      </DrawerBody>
      <DrawerFooter position="center">
        <Button
          data-testid="add-to-cancel-button"
          variant={products?.length > 0 === 'search' ? 'secondary' : 'primary'}
          fullWidth
          onClick={handleClose}
          type="submit"
        > {products?.length > 0 === 'search' ? 'Cancel' : 'OK'}
        </Button>
      </DrawerFooter>
    </>
  );
};

ProjectDefaultScreen.propTypes = {
  keyword: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  projectName: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  selectedGroup: PropTypes.string,
  groupsExist: PropTypes.bool.isRequired,
  selectedGroupId: PropTypes.shape({
    current: PropTypes.string
  }),
  products: PropTypes.arrayOf(PropTypes.shape({
    itemId: PropTypes.string,
    quantity: PropTypes.number
  })),
  onSuccessGroupClick: PropTypes.func,
  selectedProject: PropTypes.shape({
    projectId: PropTypes.string,
    projectName: PropTypes.string
  })
};

ProjectDefaultScreen.defaultProps = {
  selectedGroup: null,
  selectedGroupId: { current: '' },
  products: [],
  onSuccessGroupClick: () => {},
  selectedProject: {},
};

ProjectDefaultScreen.dataModel = extend(
  SearchResults
);
