import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonBase } from '@one-thd/sui-atomic-components/dist/button/ButtonBase';
import {
  FormController,
  FormLabel,
  FormStatusMessage
} from '@one-thd/sui-atomic-components';
import { Calendar } from '@one-thd/sui-icons';
import useId from '@one-thd/sui-atomic-components/dist/private/hooks/useId';

const createComponent = (defaultComponent, displayName) => {
  const component = (props, ref) => {
    const {
      as,
      ...other
    } = props;

    const Component = as || defaultComponent;

    return (
      <Component ref={ref} {...other} />
    );
  };

  component.displayName = displayName;
  return React.forwardRef(component);
};

const Button = createComponent(ButtonBase, 'CalendarButton');

const DateButton = React.forwardRef((props, ref) => {
  const {
    disabled = false,
    format = 'MM/DD/YYYY',
    FormStatusMessageProps,
    label = 'Date',
    onClick,
    status,
    statusMessage,
    value,
    ...other
  } = props;

  const id = useId('calendar-dialog');
  const statusMessageId = statusMessage && id ? `${id}-status-message` : undefined;

  const buttonClasses = classNames(
    'sui-group sui-inline-flex sui-items-center sui-px-3 sui-py-2 sui-justify-between sui-font-regular sui-text-base sui-leading-tight sui-h-11 sui-w-full sui-rounded-base sui-border-solid sui-border-1', {
      'hover:sui-border-input-hover focus-visible:sui-border-4 focus-visible:sui-border-highlight focus-visible:sui-text-inverse focus-visible:sui-bg-inverse': !disabled,
      'focus-visible:sui-outline focus-visible:sui-outline-1 focus-visible:sui-outline-offset-0 focus-visible:sui-outline-strongest': !disabled,
      'sui-border-strong': !disabled && !status,
      'sui-border-danger': !disabled && status === 'error',
      'sui-border-success': !disabled && status === 'success',
      'sui-border-warning': !disabled && status === 'warning',
      'sui-text-primary': value && !disabled,
      'sui-text-subtle': (value === undefined || value === '') && !disabled,
      'sui-text-inactive sui-bg-primary sui-border-primary sui-cursor-default': disabled,
    }
  );

  const spanIconClasses = classNames(
    'sui-flex sui-ml-4 sui-fill-primary', {
      'group-focus-visible:sui-fill-inverse group-focus-visible:sui-text-inverse': !disabled,
      'sui-fill-subtle': disabled
    }
  );

  const formStatusClasses = classNames({
    'sui-text-danger': !disabled && status === 'error',
    'sui-text-success': !disabled && status === 'success'
  });

  return (
    <FormController fullWidth>
      <FormLabel htmlFor="datefield-button" id="datefield-button-label">
        { label ?? 'Date' }
      </FormLabel>
      <Button
        aria-describedby={statusMessageId}
        aria-disabled={disabled}
        aria-haspopup="dialog"
        className={buttonClasses}
        disabled={disabled}
        id="datefield-button"
        onClick={onClick}
        ref={ref}
        role="button"
        unstyled
        {...other}
      >
        <span className="sui-text-ellipsis sui-overflow-hidden sui-whitespace-nowrap">
          {(value !== '') && (value !== undefined) ? value : format }
        </span>
        <span className={spanIconClasses}>
          <Calendar size="small" color="inherit" />
        </span>
      </Button>
      { statusMessage && (
        <FormStatusMessage
          className={formStatusClasses}
          id={statusMessageId}
          {...FormStatusMessageProps}
        >
          {statusMessage}
        </FormStatusMessage>
      )}
    </FormController>
  );
});

DateButton.displayName = 'DateButton';

DateButton.propTypes = {
  /**
   * If `true`, the component is inactive
   */
  disabled: PropTypes.bool,
  /**
   * The type of input masking applied to the DateField.
   */
  format: PropTypes.oneOf(['MM/DD/YYYY', 'MM/DD/YYYY–MM/DD/YYYY', 'MM/YY']),
  /**
   * Props applied to the [`FormStatusMessage`](#formstatusmessage) component.
   * @ignore
   */
  FormStatusMessageProps: PropTypes.object,
  /**
   * Component's label content.
   * @default 'Date'
   */
  label: PropTypes.string,
  /**
   * @ignore
   */
  onClick: PropTypes.func,
  /**
   * The states of validation for the TextField component.
   */
  status: PropTypes.oneOf(['error', 'success', 'warning']),
  /**
   * The status message content.
   */
  statusMessage: PropTypes.string,
  /**
   * The date value of the component.
   */
  value: PropTypes.string
};

DateButton.defaultProps = {};

export { DateButton };
