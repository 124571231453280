import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { extend, QueryProvider } from '@thd-nucleus/data-sources';
import { MiniPIPBase } from './MiniPIPBase';
import { ProductOverview } from './ProductOverview';
import { ProductSpecs } from './ProductSpecs';
import { ProductReviews } from './ProductReviews';
import { AddToContext } from '../../AddToContext';
import { SCREENS } from '../../AddToConstants';

export const HoistedFragment = ({ children }) => {
  return children;
};

HoistedFragment.dataModel = extend(MiniPIPBase, ProductOverview, ProductSpecs, ProductReviews);

export const MiniPip = ({ itemId }) => {
  const { screen, storeId, membershipInformation } = useContext(AddToContext);
  const { MINI_PIP_BASE, MINI_PIP_PRODUCT_OVERVIEW, MINI_PIP_PRODUCT_SPECS, MINI_PIP_PRODUCT_REVIEWS } = SCREENS;
  return (
    <QueryProvider
      cacheKey="MiniPIP"
      dataSource="catalog"
      defaultVariables={{
        storeId,
        skipInstallServices: true,
        loyaltyMembershipInput: membershipInformation?.data?.loyaltyMembership || null,
      }}
    >
      <HoistedFragment>
        {screen === MINI_PIP_BASE && (
          <MiniPIPBase itemId={itemId} />
        )}
        {screen === MINI_PIP_PRODUCT_OVERVIEW && (
          <ProductOverview itemId={itemId} />
        )}
        {screen === MINI_PIP_PRODUCT_SPECS && (
          <ProductSpecs itemId={itemId} />
        )}
        {screen === MINI_PIP_PRODUCT_REVIEWS && (
          <ProductReviews itemId={itemId} />
        )}
      </HoistedFragment>
    </QueryProvider>
  );
};

MiniPip.displayName = 'MiniPip';

MiniPip.propTypes = {
  itemId: PropTypes.string.isRequired,
};