import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  params, string, arrayOf,
  shape, useDataModel, extend
} from '@thd-nucleus/data-sources';
import {
  Button, DrawerBody,
  DrawerFooter, Typography,
  Skeleton, SkeletonLine,
  SkeletonBlock, RatingMeter
} from '@one-thd/sui-atomic-components';
import { Carousel } from '@one-thd/sui-carousel';
import { ArrowForward } from '@one-thd/sui-icons';
import { useStore } from '@thd-nucleus/experience-context';
import { Price } from '@thd-olt-component-react/price';
import { AddToContext } from '../../AddToContext';
import { SCREENS } from '../../AddToConstants';

export const MiniPIPBase = ({ itemId }) => {
  const store = useStore({ varnish: false, online: false });
  const { storeId } = store;
  const { setScreen } = useContext(AddToContext);
  const { PROJECT_SELECT_GROUP, MINI_PIP_PRODUCT_OVERVIEW, MINI_PIP_PRODUCT_SPECS, MINI_PIP_PRODUCT_REVIEWS } = SCREENS;

  const { data, loading } = useDataModel('product', {
    variables: {
      itemId
    },
    skip: !itemId
  });

  const { product } = data || {};
  const images = product?.media?.images || [];
  const brandName = product?.identifiers?.brandName || '';
  const productLabel = product?.identifiers?.productLabel || '';
  const averageRating = Number(product?.reviews?.ratingsReviews?.averageRating || 0);
  const totalRatings = String(product?.reviews?.ratingsReviews?.totalReviews || 0);

  const setImageDisplaySize = (url = '') => url.replace(/<SIZE>/g, '400');

  if (loading) {
    return (
      <DrawerBody>
        <SkeletonLine />
        <Skeleton grow>
          <div className="sui-w-full sui-h-full sui-px-9">
            <SkeletonBlock width={384} height={384} />
          </div>
        </Skeleton>
        <SkeletonLine variant="multi" numberOfLines={4} />
      </DrawerBody>
    );
  }

  return (
    <>
      <DrawerBody>
        <div className="sui-flex sui-flex-col sui-gap-2">
          <Typography variant="h5" data-testid="test-brandName">{brandName}</Typography>
          <Typography>{productLabel}</Typography>
          <Carousel disableMargin disableShadow type="hero" className="flex">
            {images.map((image, index) => (
              <div key={`hero-image-${index}`}>
                <img
                  src={setImageDisplaySize(image.url)}
                  alt={productLabel}
                  width={384}
                  height={384}
                  title={productLabel}
                  loading="lazy"
                />
              </div>
            ))}
          </Carousel>
          <Price
            itemId={itemId}
            large={false}
            storeId={storeId}
            displayEachUom={false}
            showPreferredPricingBadge
            hideForProductTypes={['HDQC']}
          />
        </div>
        <div className="sui-flex sui-flex-col sui-gap-2">
          {/* eslint-disable react/button-has-type */}
          <button
            className="sui-grow sui-flex sui-justify-between sui-px-1 sui-h-11 sui-items-center"
            onClick={() => setScreen(MINI_PIP_PRODUCT_OVERVIEW)}
            data-testid="product-overview-button"
          >
            <Typography variant="h6">Product Overview</Typography>
            <ArrowForward />
          </button>
          <button
            className="sui-grow sui-flex sui-justify-between sui-px-1 sui-h-11 sui-items-center"
            onClick={() => setScreen(MINI_PIP_PRODUCT_SPECS)}
            data-testid="product-specs-button"
          >
            <Typography variant="h6">Specs</Typography>
            <ArrowForward />
          </button>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            className="sui-grow sui-flex sui-justify-between sui-px-1 sui-h-11 sui-items-center sui-cursor-pointer"
            onClick={() => setScreen(MINI_PIP_PRODUCT_REVIEWS)}
            data-testid="product-reviews-button"
          >
            <div className="sui-flex sui-items-center">
              <Typography variant="h6">
                Reviews
              </Typography>
              <RatingMeter
                label={totalRatings}
                value={averageRating}
              />
            </div>
            <ArrowForward />
          </div>
        </div>
      </DrawerBody>
      <DrawerFooter position="center">
        <Button
          data-testid="miniPIP-AITP-button"
          variant="secondary"
          fullWidth
          onClick={() => setScreen(PROJECT_SELECT_GROUP)}
          type="submit"
        >
          Add to Project
        </Button>
      </DrawerFooter>
    </>
  );
};

MiniPIPBase.displayName = 'MiniPIPBase';

MiniPIPBase.propTypes = {
  itemId: PropTypes.string.isRequired,
};

MiniPIPBase.dataModel = extend(
  {
    product: params({
      itemId: string().isRequired(),
      dataSource: string()
    }).shape({
      itemId: string(),
      dataSources: string(),
      identifiers: shape({
        brandName: string(),
        productLabel: string(),
        storeSkuNumber: string()
      }),
      media: shape({
        images: arrayOf(shape({
          url: string(),
          type: string(),
          subType: string()
        }))
      }),
      reviews: shape({
        ratingsReviews: shape({
          averageRating: string(),
          totalReviews: string()
        })
      })
    })
  },
  Price
);
