import {
  params, string, arrayOf, shape, customType, number,
  useDataModel, useLazyDataModel, extend, QueryProvider, bool
} from '@thd-nucleus/data-sources';

const useProjects = (props) => {
  const {
    data: projects, error, loading, refetch, client
  } = useDataModel('projects', {
    variables: props,
    ssr: false,
    // add back with customer check
    // skip: !props?.accountId,
    context: { withAuth: true }
  });

  return { projects, refetch, loading };
};

const useProjectDetails = (props) => {
  const {
    data: projectData,
    loading: projectLoading,
    refetch: projectRefetch
  } = useDataModel('projectDetails', {
    variables: {
      projectId: props.projectId
    },
    context: { withAuth: true },
    skip: !props?.projectId
  });

  return { projectData, projectLoading, projectRefetch };
};

const useCreateGroup = (props) => {
  const [createGroup, createGroupResponse] = useDataModel('createProjectListGroup', {
    variables: props,
    context: { withAuth: true },
    fetchPolicy: 'no-cache',
    skip: !props?.projectId
  });
  return { createGroup, createGroupResponse };
};

const projectDetailsModel = {
  projectDetails: params({ projectId: string().isRequired() }).shape({
    id: string(),
    defaultListId: string(),
    defaultListMutationKey: string(),
    projectName: string(),
    propertyType: string(),
    addresses: arrayOf(
      shape({
        id: string(),
        addressLine1: string(),
        addressLine2: string(),
        city: string(),
        state: string(),
        country: string(),
        zipCode: string(),
        addressType: string(),
      })
    ),
    itemGroups: arrayOf(shape({
      id: string(),
      groupName: string()
    }))
  })
};

const filterType = customType('Filter').shape({
  searchBy: customType('searchBy').enum([
    'PROJECT_NAME',
    'STATUS', 'PROJECT_ID'
  ], 'STATUS'),
  search: string()
});

const projectsModel = {
  projects: params({
    page: number(),
    pageSize: number(),
    accountId: string().isRequired(),
    filter: arrayOf(filterType)
  }).shape({
    totalProjects: number(),
    projects: arrayOf(
      shape({
        id: string(),
        projectId: string(),
        projectName: string(),
        status: string(),
        modifyDateTime: string()
      })
    )
  })
};

const createGroupModel = {
  createProjectListGroup: params({
    projectId: string().isRequired(),
    listId: string().isRequired(),
    mutationKey: string().isRequired(),
    groupName: string().isRequired()
  }).mutation().shape({
    groupId: string(),
  })
};

export {
  useProjectDetails,
  useCreateGroup,
  useProjects,
  projectsModel,
  projectDetailsModel,
  createGroupModel
};
