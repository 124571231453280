/* istanbul ignore file */
// ignore this file from test coverage as it will be implemented for actual use in the near future

import { useContext } from 'react';

import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { useHelmet, LinkTag } from '@thd-nucleus/thd-helmet';

import Helper from '../../component/Helper';
import { IMAGE_TYPES } from '../../constants';

// Upcoming changes to this hook:
// Migrate to EdgeWorker - the decision from DE will be available server and client-side.
//    DE Call can be removed, loading placeholder can be removed. Metadata handling should be fine to stay
//    unless we decide to move this sort of logic to the appropriate spot(s?) in the metadata component

export const useDecisionEngine = ({ media, isMobile, pageType }) => {
  // TODO: Get x-experiment header from context
  // const { segment } = useContext(ExperienceContext);

  // main killswitch for DE feature
  const enableDeFeature = useConfigService('fs:enableDeFeature');

  // TODO: Get Decision from x-experiment header
  const decision = '';
  const imageType = Helper.armToImageMapping({ decision });

  // TODO: Add Analytics for DE, we'll need this variable
  // const threeHrExpiration = new Date(new Date().setHours(new Date().getHours() + 3)).getTime() - Date.now();

  const productMetaDataHandler = () => {
    // TODO: this will be determined by the x-experiment header in the future
    const segmentIsPartOfDETest = false;
    if (!(segmentIsPartOfDETest && enableDeFeature)) {
      return [];
    }

    const minimizeImage = isMobile && pageType === 'pep';
    const { image: primaryImage, images = [] } = media || {};
    const primaryImageUrl = primaryImage?.url?.replace(/_\d+./gm, minimizeImage ? '_300' : '_600.');
    const tags = [];

    const filteredImages = images?.filter((image) => {
      return IMAGE_TYPES.find((type) => image?.type === type);
    }) || [];

    filteredImages.forEach(({ url, type }) => {
      const imgUrl = url?.replace(/<SIZE>/ig, minimizeImage ? '300' : '600');

      // Skip primary image, as this is already loaded in the head within ProductMetadata.js
      if (imgUrl !== primaryImageUrl) {
        tags.push(
          new LinkTag({
            rel: 'preload',
            href: imgUrl,
            id: `preLoadImg ${type}`,
            as: 'image',
            media: `(min-width: ${minimizeImage ? '300px' : '600px'})`,
            fetchpriority: 'high'
          })
        );
      }
    });

    return tags;
  };

  // TODO: Add x-experiment header to dependency array
  useHelmet('decisionEngine.mediaGallery', {}, productMetaDataHandler, []);

  // TODO: Maybe make this return imageType instead of an object
  // return {
  //   imageType,
  // };

  // TODO: Remove this return statement once EW migration changes are in place
  // This ensures that this hook causes no real side effects in the meantime
  return {
    imageType: undefined
  };
};