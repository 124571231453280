import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  params, string, shape, number, client, extend, useDataModel, arrayOf
} from '@thd-nucleus/data-sources';
import { useTheme } from '@thd-olt-component-react/theme-provider';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import THDStorageUtils, { COOKIE_STORAGE } from '@thd-olt-global/thd-storage-utils';
import { DrawerHeader } from '@one-thd/sui-atomic-components';
import { AddToContext } from '../../AddToContext';
import { useListDetails } from '../../hooks/useLists';
import { SCREENS } from '../../AddToConstants';
import { SignIn } from '../../common/addto-signin/SignIn';
import { CreateList } from './CreateList';
import { SelectList } from './SelectList';
import { AddToListSuccess } from './AddToListSuccess';
import { AddToLoading } from '../../common/addto-loading/AddToloading';

export const AddToList = (props) => {
  const { products, showCount, skipListId, isSharedList } = props;
  const theme = useTheme(AddToList, props);
  const { disableSignInDrawer } = theme.props;
  const { hideCount, showPlusSign } = theme.props;
  const {
    addToType, storeId, setScreen, screen, handleClose
  } = useContext(AddToContext);
  const { svocID, userID, customerType, isLoggedIn } = useThdCustomer() || {};
  const [favoriteCount, setFavoriteCount] = useState(0);
  const [newListDetails, setNewListDetails] = useState({
    listName: '', listId: ''
  });
  const {
    LIST_CREATE_LIST, LIST_SELECT_LIST, SIGN_IN, LIST_SUCCESS, LOADING
  } = SCREENS;
  let storeFrontSVOCID;
  let storeFrontUserID;

  const { data } = useDataModel('product', {
    variables: {
      itemId: products[0]?.itemId,
      storeId
    },
    skip: !products[0]?.itemId || products?.length > 1
  });

  const productData = data?.product || {};
  const productValue = productData?.pricing?.value;
  const identifiers = productData?.identifiers;
  const primaryImage = productData?.media?.images?.find(
    (img) => img.type === 'IMAGE' && img.subType === 'PRIMARY'
  );

  const storefrontCookieData = THDStorageUtils.get(COOKIE_STORAGE, { key: 'THD_STOREFRONT' });

  if (storefrontCookieData && storefrontCookieData !== 'undefined') {
    const storefrontPayload = storefrontCookieData.split('.')[1];
    /* istanbul ignore next */
    if (storefrontPayload) {
      const decodedStorefrontPayload = Buffer.from(storefrontPayload, 'base64').toString();
      const { SVOCID } = JSON.parse(decodedStorefrontPayload);
      storeFrontSVOCID = SVOCID;
      storeFrontUserID = (storeFrontSVOCID + '_STOREFRONT');
    }
  }

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('AddToListDrawer.ready');
  }, []);

  const { listData, listLoading, listRefetch } = useListDetails({
    customerAccountID: storeFrontSVOCID || svocID,
    userId: storeFrontSVOCID ? storeFrontUserID : userID,
    customerType: storeFrontSVOCID ? null : customerType?.toUpperCase(),
    skip: storeFrontSVOCID
    // eslint-disable-next-line max-len
      ? ((!storeFrontSVOCID || !storeFrontUserID) || addToType !== 'List') : ((!svocID || !userID) || addToType !== 'List')
  });

  const { listDetails: listDetailsData = null } = listData?.listDetails?.listResponse || {};
  const listDetails = (listDetailsData || []).filter((list) => list.listId !== skipListId);
  const listsExist = !!listDetails?.length;

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('AddToListDrawer.ready');
  }, []);

  const onCreateList = () => {
    listRefetch();
  };

  const favoriteDetailCount = productData?.favoriteDetail?.count;

  useEffect(() => {
    setFavoriteCount(favoriteDetailCount);
  }, [favoriteDetailCount]);

  useEffect(() => {
    if (!disableSignInDrawer && !isLoggedIn) {
      setScreen(SIGN_IN);
    } else if (listLoading) {
      setScreen(LOADING);
    } else if (listsExist) {
      setScreen(LIST_SELECT_LIST);
    } else {
      setScreen(LIST_CREATE_LIST);
    }
  }, [isLoggedIn, listsExist, listLoading]);

  return (
    <>
      <DrawerHeader
        data-testid="add-to-drawer-header"
        title={screen === LIST_CREATE_LIST ? 'Create List' : 'Add to list'}
        onClose={handleClose}
      />
      {screen === SIGN_IN && (
        <SignIn
          onClose={handleClose}
        />
      )}
      {screen === LOADING && (
        <AddToLoading />
      )}
      {screen === LIST_CREATE_LIST && (
        <CreateList
          onClose={handleClose}
          onCreate={onCreateList}
          products={products}
          isExistingLists={listsExist}
          isSharedList={isSharedList}
          setFavoriteCount={setFavoriteCount}
          favoriteCount={favoriteCount}
          productValue={productValue}
          identifiers={identifiers}
          primaryImage={primaryImage}
          setNewListDetails={setNewListDetails}
        />
      )}
      {screen === LIST_SELECT_LIST && (
        <SelectList
          products={products}
          listDetails={listDetails}
          onClose={handleClose}
          isSharedList={isSharedList}
          setFavoriteCount={setFavoriteCount}
          favoriteCount={favoriteCount}
          productValue={productValue}
          identifiers={identifiers}
          primaryImage={primaryImage}
          setNewListDetails={setNewListDetails}
        />
      )}
      {screen === LIST_SUCCESS && (
        <AddToListSuccess
          newListDetails={newListDetails}
          onClose={handleClose}
        />
      )}
    </>
  );
};

AddToList.displayName = 'AddToList';

AddToList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    itemId: PropTypes.string,
    quantity: PropTypes.number
  })),
  skipListId: PropTypes.bool,
  showCount: PropTypes.bool,
  isSharedList: PropTypes.bool
};

AddToList.defaultProps = {
  products: [],
  skipListId: false,
  showCount: false,
  isSharedList: false
};

const favoriteDetailCount = shape({
  count: number()
});

if (favoriteDetailCount.skip) {
  favoriteDetailCount.skip('skipFavoriteCount', false);
}

AddToList.dataModel = extend(
  {
    product: params({ itemId: string().isRequired(), dataSource: string() }).shape({
      itemId: string(),
      dataSource: string(),
      dataSources: string(),
      favoriteDetail: client(favoriteDetailCount),
      pricing: params({ storeId: string() }).shape({
        value: number({ float: true })
      }),
      identifiers: shape({
        brandName: string(),
        productLabel: string()
      }),
      media: shape({
        images: arrayOf(shape({
          url: string(),
          type: string(),
          subType: string()
        }))
      }),
    })
  }
);
