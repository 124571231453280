/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { FormController, FormLabel, TextField } from '@one-thd/sui-atomic-components';

const DeliveryZipCode = ({ deliveryZip, setDeliveryZip, setDisabled = () => { } }) => {
  const [zipValidation, setZipValidation] = useState({ status: undefined });

  const textFieldHandler = (event) => {
    // zip validate success
    if (!event?.target?.value || /^[0-9\b]+$/.test(event?.target?.value)) {
      setDeliveryZip(event.target.value);
      setZipValidation({ status: undefined });
      setDisabled(false);
    }
    // zip validation error
    if (!event?.target?.value.match(/^\d{5}$/)) {
      setZipValidation({ status: 'error', errorMessage: 'Please enter a valid ZIP Code' });
      setDisabled(true);
    }
  };

  return (
    <FormController className="sui-mt-6">
      <FormLabel
        id="delivery-zipCode"
        className="sui-cursor-default sui-text-base"
        htmlFor="delivery-zipCodeInput"
      >
        Delivery Zip Code *
      </FormLabel>
      <div>
        <TextField
          id="delivery-zipCodeInput"
          fullWidth
          placeholder={deliveryZip}
          value={deliveryZip}
          onChange={textFieldHandler}
          status={zipValidation.status}
          statusMessage={zipValidation.errorMessage}
          helpMessage="Verify your delivery ZIP Code. We use this to check availability of items in your area."
        />
      </div>
    </FormController>
  );
};

DeliveryZipCode.propTypes = {};

DeliveryZipCode.displayName = 'DeliveryZipCode';

export { DeliveryZipCode };
