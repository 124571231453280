import {
  params,
  string,
  shape,
  bool,
  arrayOf,
  alias,
  number
} from '@thd-nucleus/data-sources';

export const ProductDataModel = {
  clientOnlyProduct: alias('product').params({ itemId: string().isRequired() }).shape({
    itemId: string(),
    dataSources: string(),
    bundleSpecificationDetails: shape({
      parents: arrayOf(shape({
        parentId: string()
      }))
    }),
    info: shape({
      recommendationFlags: shape({
        visualNavigation: bool(),
        pipCollections: bool(),
        ACC: bool(),
        collections: bool(),
        batItems: bool(),
        packages: bool(),
        frequentlyBoughtTogether: bool(),
        bundles: bool()
      })
    }),
    identifiers: shape({
      productType: string()
    }),
    availabilityType: shape({
      discontinued: bool(),
      type: string()
    }),
    fulfillment: params({ storeId: string() }).shape({
      backordered: bool(),
      fulfillmentOptions: arrayOf(
        shape({
          type: string(),
          fulfillable: bool(),
          services: arrayOf(
            shape({
              type: string(),
              locations: arrayOf(
                shape({
                  isAnchor: bool(),
                  inventory: shape({
                    isLimitedQuantity: bool(),
                    isOutOfStock: bool()
                  })
                })
              )
            })
          )
        })
      )
    }),
    media: shape({
      image: shape({
        url: string()
      }).client()
    })
  })
};

export const BundlesDataModel = {
  products: params({ itemIds: arrayOf(string().isRequired()).isRequired() }).arrayOf(shape({
    itemId: string(),
    dataSources: string(),
    identifiers: shape({
      productLabel: string(),
      brandName: string(),
      canonicalUrl: string()
    }),
    media: shape({
      images: arrayOf(shape({
        url: string(),
        sizes: arrayOf(string())
      }))
    }),
    bundleSpecificationDetails: shape({
      experienceType: string(),
      brand: string(),
      components: arrayOf(shape({
        id: string(),
        defaultProductId: string(),
        quantity: string(),
        type: string()
      }))
    })
  }))
};

export const BundleProductsDataModel = {
  products: params({ itemIds: arrayOf(string().isRequired()).isRequired() }).arrayOf({
    itemId: string(),
    identifiers: shape({
      itemId: string(),
      productType: string(),
      brandName: string()
    }),
    pricing: params({ storeId: string(), isBrandPricingPolicyCompliant: bool() }).shape({
      original: number({ float: true }),
      mapAboveOriginalPrice: bool(),
      mapDetail: shape({
        percentageOff: number({ float: true }),
        dollarOff: number({ float: true }),
        mapPolicy: string(),
        mapOriginalPriceViolation: bool(),
        mapSpecialPriceViolation: bool()
      }),
      message: string(),
      promotion: shape({
        type: string(),
        description: shape({
          shortDesc: string(),
          longDesc: string()
        }),
        dollarOff: number({ float: true }),
        percentageOff: number({ float: true })
      }),
      promotionalAdjustments: arrayOf(shape({
        type: string(),
        description: shape({
          shortDesc: string(),
          longDesc: string()
        }),
        dollarOff: number({ float: true }),
        percentageOff: number({ float: true }),
        promoId: string()
      })),
      specialBuy: number({ float: true }),
      value: number({ float: true })
    }),
    fulfillment: params({ storeId: string() }).shape({
      backordered: bool(),
      fulfillmentOptions: arrayOf(
        shape({
          type: string(),
          fulfillable: bool(),
          services: arrayOf(
            shape({
              type: string(),
              locations: arrayOf(
                shape({
                  isAnchor: bool(),
                  inventory: shape({
                    isLimitedQuantity: bool(),
                    isOutOfStock: bool()
                  })
                })
              )
            })
          )
        })
      )
    })
  })
};