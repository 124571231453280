import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  params, string, arrayOf, shape, extend
} from '@thd-nucleus/data-sources';
import {
  Button,
  Drawer
} from '@one-thd/sui-atomic-components';
import { Add } from '@one-thd/sui-icons';
import { useStore } from '@thd-nucleus/experience-context';
import { ADD_TO_TYPE, SCREENS, TRACK } from './AddToConstants';
import { PreviouslyPurchased } from './common/previously-purchased/PreviouslyPurchased';
import { AddToContext } from './AddToContext';
import { SelectGroup } from './addto/project/SelectGroup';
import { ProjectDefaultScreen } from './addto/project/ProjectDefaultScreen';
import {
  projectsModel,
  projectDetailsModel,
  createGroupModel,
} from './hooks/useProjects';
import { listDetailsModel, updateListModel, createListModel } from './hooks/useLists';
import { AddToProject } from './addto/project/AddToProject';
import { AddToList } from './addto/list/AddToList';
import { CopyProject } from './addto/copyProject/CopyProject';
import { AddToSelection } from './common/addto-selection/AddToSelection';
import { SaveToFavoriteIconButton } from './addto/list/SaveToFavoriteIconButton';
import { useBenefitCheck } from './hooks/useBenefitCheck';

export const AddTo = (props) => {
  const {
    project,
    list,
    addToType: addToTypeFromProp,
    addToFallbackType,
    products,
    copyProjectData,
    storeId: storeIdFromProp,
    CustomAddToActionElement,
    showSelectionScreen,
    onSuccessfulAddToGroup,
    showBackButton
  } = props;
  const defaultAddToType = addToTypeFromProp === ADD_TO_TYPE.PROJECT && addToFallbackType !== null
    ? addToFallbackType : addToTypeFromProp;
  const [addToType, setAddToType] = useState(defaultAddToType || null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [pageSize, setPageSize] = useState(30);
  const store = useStore();
  const storeId = storeIdFromProp || store?.storeId;
  const [open, setOpen] = useState(false);
  const [screen, setScreen] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedProductDetails, setSelectedProductDetails] = useState(
    { itemId: null, quantity: null, productData: null }
  );

  const { isProjectsEligible } = useBenefitCheck({
    skip: !(addToTypeFromProp === ADD_TO_TYPE.PROJECT || showSelectionScreen)
  });

  useEffect(() => {
    if (addToTypeFromProp === ADD_TO_TYPE.PROJECT) {
      if (!isProjectsEligible) {
        setAddToType(addToFallbackType);
      } else {
        setAddToType(addToTypeFromProp);
      }
    }
  }, [isProjectsEligible]);

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS?.lifeCycle?.trigger('add-to.ready');
  }, []);

  const {
    PROJECT_SELECT_GROUP, PROJECT_DEFAULT, MINI_PIP_BASE, PROJECT_SELECT_PROJECT, ADD_TO_SELECTION
  } = SCREENS;

  const handleOpen = () => {
    if (showSelectionScreen) {
      setScreen(ADD_TO_SELECTION);
    }
    if (addToType === 'Project') {
      if (products?.length > 0) {
        setScreen(PROJECT_SELECT_PROJECT);
      } else {
        setScreen(PROJECT_DEFAULT);
      }
    }
    setOpen(true);
    if (addToType === 'Project') {
      if (products?.length === 0) {
        window.LIFE_CYCLE_EVENT_BUS.trigger('projects: add items overlay');
      }
    }
    if (window.digitalData?.page?.pageInfo?.pageType === 'pip') {
      window.LIFE_CYCLE_EVENT_BUS.trigger(TRACK.BUTTON_CLICK, {});
    }
    if (addToType === 'List') {
      LIFE_CYCLE_EVENT_BUS.trigger(TRACK.OPEN_DRAWER, {});
    }
  };

  const handleClose = () => {
    setInitialLoad(true);
    setScreen(null);
    if (showSelectionScreen) {
      setAddToType(null);
    }
    setShowSuccessMessage(false);
    setSelectedGroup(null);
    setSelectedProductDetails({ itemId: null, quantity: null, productData: null });
    setOpen(false);
  };

  const handleBackPress = () => {
    if (screen === PROJECT_SELECT_GROUP || screen === MINI_PIP_BASE) {
      setScreen(PROJECT_DEFAULT);
      setSelectedGroup(null);
      setSelectedProductDetails({ itemId: null, quantity: null, productData: null });
    }
    if (screen.startsWith('MINI_PIP')) {
      setScreen(MINI_PIP_BASE);
    }
  };

  const context = {
    projectRefetch: () => { },
    handleBackPress,
    handleClose,
    screen,
    setScreen,
    selectedProductDetails,
    setSelectedProductDetails,
    showSuccessMessage,
    setShowSuccessMessage,
    addToType,
    products,
    selectedGroup,
    setSelectedGroup,
    storeId
  };

  if (addToType === null && !showSelectionScreen) {
    return null;
  }
  const showCustomButton = CustomAddToActionElement && addToType === addToTypeFromProp;
  const { showIconButton = false } = list;
  return (
    <>
      {
        showSelectionScreen && (
          <Button
            startIcon={Add}
            variant="tertiary"
            onClick={handleOpen}
            disabled={products?.length === 0 && copyProjectData?.items?.length === 0}
          >
            Add to
          </Button>
        )
      }
      {
        showIconButton && (
          <SaveToFavoriteIconButton
            storeId={storeId}
            products={products}
            handleOpen={handleOpen}
          />
        )
      }
      {
        showCustomButton && (
          <CustomAddToActionElement onClick={handleOpen} />
        )
      }
      {
        !showCustomButton && !showIconButton && !showSelectionScreen && (
          <Button
            fullWidth
            variant="secondary"
            onClick={handleOpen}
            aria-label={`Add to ${addToType}`}
            data-testid={`add-to-${addToType}`}
          >
            Add to {addToType === 'CopyProject' ? 'Project' : addToType}
          </Button>
        )
      }
      <AddToContext.Provider value={context}>
        <Drawer open={open} onClose={handleClose}>
          <>
            {screen === ADD_TO_SELECTION && (
              <AddToSelection
                products={products}
                setAddToType={setAddToType}
                handleClose={handleClose}
                isProjectsEligible={isProjectsEligible}
                copyProjectData={copyProjectData?.items}
              />
            )}
            {
              (addToType === 'Project') && (
                <AddToProject
                  products={products}
                  projectId={project?.projectId}
                  onSuccessGroupClick={project?.onSuccessGroupClick}
                  expandSelectedGroup={project?.expandSelectedGroup}
                  initialLoad={initialLoad}
                  setInitialLoad={setInitialLoad}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  onSuccessfulAddToGroup={onSuccessfulAddToGroup}
                  showBackButton={showBackButton}
                />
              )
            }
            {
              (addToType === 'List') && (
                <AddToList
                  products={products}
                  showCount={list?.showCount}
                  skipListId={list?.skipListId}
                  isSharedList={list?.isSharedList}
                />
              )
            }
            {
              (addToType === 'CopyProject') && (
                <CopyProject
                  copyProjectData={copyProjectData}
                />
              )
            }
          </>
        </Drawer>
      </AddToContext.Provider>
    </>
  );
};

AddTo.displayName = 'AddTo';

AddTo.propTypes = {
  addToType: PropTypes.oneOf([ADD_TO_TYPE.PROJECT, ADD_TO_TYPE.LIST, ADD_TO_TYPE.QUOTE, ADD_TO_TYPE.CART, ADD_TO_TYPE.COPY_PROJECT, null]),
  addToFallbackType: PropTypes.oneOf([ADD_TO_TYPE.PROJECT, ADD_TO_TYPE.LIST, ADD_TO_TYPE.QUOTE, ADD_TO_TYPE.CART, null]),
  products: PropTypes.arrayOf(PropTypes.shape({
    itemId: PropTypes.string,
    quantity: PropTypes.number
  })),
  copyProjectData: PropTypes.shape({
    sourceList: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string),
    targetProjectId: PropTypes.string
  }),
  storeId: PropTypes.string,
  CustomAddToActionElement: PropTypes.func,
  showSelectionScreen: PropTypes.bool,
  project: PropTypes.shape({
    projectId: PropTypes.string,
    onSuccessGroupClick: PropTypes.func,
    expandSelectedGroup: PropTypes.func,
  }),
  list: PropTypes.shape({
    showCount: PropTypes.bool,
    isSharedList: PropTypes.bool,
    showIconButton: PropTypes.bool,
    skipListId: PropTypes.string,
  }),
  onSuccessfulAddToGroup: PropTypes.func,
  showBackButton: PropTypes.bool
};

AddTo.defaultProps = {
  project: {
    projectId: null,
    onSuccessGroupClick: () => { },
    expandSelectedGroup: () => { },
  },
  list: {
    skipListId: null,
    showIconButton: false,
    showCount: false,
    isSharedList: false,
  },
  products: [],
  copyProjectData: {},
  storeId: null,
  CustomAddToActionElement: null,
  showSelectionScreen: false,
  addToType: null,
  addToFallbackType: null,
  onSuccessfulAddToGroup: () => { },
  showBackButton: true
};

AddTo.dataModel = extend({
  product: params({
    itemId: string().isRequired(),
    dataSource: string()
  }).shape({
    itemId: string(),
    dataSources: string(),
    identifiers: shape({
      brandName: string(),
      productLabel: string(),
      storeSkuNumber: string()
    }),
    media: shape({
      images: arrayOf(shape({
        url: string(),
        type: string(),
        subType: string()
      }))
    }),
    reviews: shape({
      ratingsReviews: shape({
        averageRating: string(),
        totalReviews: string()
      })
    })
  })
},
useBenefitCheck,
AddToProject,
projectsModel,
ProjectDefaultScreen,
PreviouslyPurchased,
SelectGroup,
AddToList,
SaveToFavoriteIconButton,
// CreateGroup,
projectDetailsModel,
createGroupModel,
listDetailsModel,
updateListModel,
createListModel,
CopyProject
);
