import THDStorageUtils, { LOCAL_STORAGE } from '@thd-olt-global/thd-storage-utils';
import {
  SEARCH_HISTORY_STORAGE_KEY, ERROR
} from './AddToConstants';

const {
  EMPTY_GROUP_NAME_MESSAGE,
  INVALID_GROUP_LENGTH_MESSAGE,
  INVALID_PROJECT_TEXT_MESSAGE
} = ERROR;
export const fetchSearch = () => {
  const history = THDStorageUtils.get(LOCAL_STORAGE, { key: SEARCH_HISTORY_STORAGE_KEY });
  if (!history || history === 'undefined') return [];
  return JSON.parse(history).slice(0, 10);
};

export const searchHystoryFormat = (data) => {
  return data?.map(({ keyword }) => {
    const cleanKeyword = decodeURIComponent(keyword) || keyword;
    return ({ link: `/s/${keyword}`, title: cleanKeyword });
  });
};

export const generateLoadedProducts = (existingProducts, newProducts) => {
  const existingItemIds = existingProducts.map((product) => product.itemId);
  const productsToLoad = newProducts?.filter((product) => !existingItemIds.includes(product.id)) || [];
  return [...existingProducts, ...productsToLoad];
};

export const getNvalue = (path) => {
  const pathArr = path.split('/');
  const fullNValue = pathArr.find((part) => part.startsWith('N-') || part.startsWith('Ntt-'));
  if (fullNValue) {
    const nValue = fullNValue.split('?')?.[0];
    if (nValue.startsWith('N-')) {
      return nValue.slice(2);
    }
    return nValue.slice(4);
  }
  return null;
};

export const triggerAnalytics = (event, payload) => {
  LIFE_CYCLE_EVENT_BUS.trigger(event, payload);
};

export const addNonQuotableProductVariables = (product) => {
  const { identifiers, info } = product || {};

  const isMajorAppliance = identifiers?.productType === 'MAJOR_APPLIANCE';
  if (isMajorAppliance) {
    return {
      isQuotable: false,
      notQuotableLabel: 'Major appliances'
    };
  }

  const isConfigurableBlinds = identifiers?.productType === 'CONFIGURABLE_BLINDS';
  if (isConfigurableBlinds) {
    return {
      isQuotable: false,
      notQuotableLabel: 'GCC configurators'
    };
  }

  const isLiveGoods = info?.isLiveGoodsProduct;
  if (isLiveGoods) {
    return {
      isQuotable: false,
      notQuotableLabel: 'Live goods'
    };
  }

  return {};
};

export const validateGroupName = (value) => {
  const validCharacters = /^(?=.{0,50}$)[ [\]{}|a-z|A-Z|!@#$%^&*()0-9-_+,.?"':;\\]+$/;
  const blank = !(value || '').replace(/\s/g, '');
  let isValid = validCharacters.test(value);
  const charLimit = value?.length > 50;
  let errorMessage = '';
  if (!isValid) {
    if (blank) {
      errorMessage = EMPTY_GROUP_NAME_MESSAGE;
    } else if (charLimit) {
      errorMessage = INVALID_GROUP_LENGTH_MESSAGE;
    } else {
      errorMessage = INVALID_PROJECT_TEXT_MESSAGE;
    }
  }
  return { isError: !isValid, errorMessage };
};
